<template>
    <section class="z-0 mt-10">
        <div id="headline">
            <h1 class="text-center text-ctlgray font-bold text-2xl mt-6 mx-6">Sie interessieren sich für den ImuPro?</h1>
            <p class="mt-4 mx-6 md:mx-48 xl:mx-72 text-justify">Der ImuPro- Test liefert Ihnen zuverlässige Ergebnisse über Ihre Nahrungsmittelunverträglichkeiten!</p>
            <p class="mt-2 mx-6 md:mx-48 xl:mx-72 text-justify">Wir arbeiten mit Therapeuten zusammen, die Ihnen das ImuPro- Konzept verständlich erklären und Sie auf dem Weg Ihrer Ernährungsumstellung professionell begleiten.</p>
            <p class="mt-2 mx-6 md:mx-48 xl:mx-72 text-justify">Als Speziallabor der IgG- Diagnostik bieten wir Ihnen medizinisch fundierte Analysen und unterstützen Sie im Anschluss gerne bei der Durchführung: unsere ImuPro Ernährungsberatung steht Ihnen bei weiteren Fragen gerne zur Verfügung und berät Sie kompetent!</p>
            <p class="mt-2 mx-6 md:mx-48 xl:mx-72 text-justify">Schritt für Schritt zum ImuPro- Test:</p>
        </div>

        <div class="grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-6 mt-6 z-0 hidden md:grid">
            <div id="introduction">
                <div class="my-6 mx-6 md:mx-12 xl:mx-20">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">1</span> Kurze Schritte</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/1.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Wir zeigen Ihnen, wie unser Labortest abläuft: Schnell und sicher mit unserem Erfolgskonzept.</p>
                </div>
            </div>
            <div id="gettherapist">
                <div class="my-6 mx-6 md:mx-12 xl:mx-20">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">2</span> Therapeuten finden</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/2.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Wir sind Ihnen gerne behilflich einen Therapeuten in ihrer Nähe zu finden. Anruf genügt - Sie erhalten Vorschläge unter <a href="tel:04403626050">04403 62605 - 0</a> oder <a href="mailto:service@ctl-labor.de">service@ctl-labor.de</a>. </p>
                </div>
            </div>
            <div id="visittherapist">
                <div class="my-6 mx-6 md:mx-12 xl:mx-20">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">3</span> Therapeut aufsuchen</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/3.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Haben Sie den für Sie einen Therapeuten gefunden? Er wird Sie zum ImuPro Konzept beraten, die erfolderliche Blutprobe entnehmen und diese Probe an unser Labor senden.</p>
                </div>
            </div>
            <div id="sendin">
                <div class="my-6 mx-6 md:mx-12 xl:mx-20">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">4</span> Probenversand</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/4.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Mit Ihrem Einverständnis sendet Ihr Therapeut die Probe inklusive Ihres Auftrags an uns. Ab diesem Zeitpunkt können Sie auf unsere Expertise vertrauen.</p>
                </div>
            </div>
            <div id="sampletested">
                <div class="my-6 mx-6 md:mx-12 xl:mx-20">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">5</span> Laboranalyse</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/5.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Ihre Blutprobe untersuchen wir auf bis zu 270 Reaktionen auf Lebensmittel. Sie können auf Wunsch auch gerne mit einer kleineren Testvariante starten. Ihr Therapeut berät Sie dazu gerne!</p>
                </div>
            </div>
            <div id="getresult">
                <div class="my-6 mx-6 md:mx-12 xl:mx-20">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">6</span> ImuPro- Testergebnis</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/6.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">In wenigen Tagen erhalten Sie Ihren persönlichen Befund. Sie starten mit Ihrer Ernährungsumstellung und werden bei Fragen professionell von uns unterstützt.</p>
                </div>
            </div>
        </div>

        <div class="grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-6 mt-6 z-0 grid md:hidden">
            <div id="introduction" v-if="slide==1">
                <div class="m-6">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">1</span> Kurze Schritte</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/1.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Wir zeigen Ihnen, wie unser Labortest abläuft: Schnell und sicher mit unserem Erfolgskonzept.</p>
                </div>
            </div>
            <div id="gettherapist" v-if="slide==2">
                <div class="m-6">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">2</span> Therapeuten finden</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/2.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Wir sind Ihnen gerne behilflich einen Therapeuten in ihrer Nähe zu finden. Anruf genügt - Sie erhalten Vorschläge unter <a href="tel:04403626050">04403 62605 - 0</a> oder <a href="mailto:service@ctl-labor.de">service@ctl-labor.de</a>. </p>
                </div>
            </div>
            <div id="visittherapist" v-if="slide==3">
                <div class="m-6">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">3</span> Therapeut aufsuchen</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/3.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Haben Sie den für Sie einen Therapeuten gefunden? Er wird Sie zum ImuPro Konzept beraten, die erfolderliche Blutprobe entnehmen und diese Probe an unser Labor senden.</p>
                </div>
            </div>
            <div id="sendin" v-if="slide==4">
                <div class="m-6">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">4</span> Probenversand</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/4.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Mit Ihrem Einverständnis sendet Ihr Therapeut die Probe inklusive Ihres Auftrags an uns. Ab diesem Zeitpunkt können Sie auf unsere Expertise vertrauen.</p>
                </div>
            </div>
            <div id="sampletested" v-if="slide==5">
                <div class="m-6">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">5</span> Testung</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/5.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">Ihre Blutprobe untersuchen wir auf bis zu 270 Reaktionen auf Lebensmittel. Sie können auf Wunsch auch gerne mit einer kleineren Testvariante starten. Ihr Therapeut berät Sie dazu gerne!</p>
                </div>
            </div>
            <div id="getresult" v-if="slide==6">
                <div class="m-6">
                    <h1 class="text-center text-ctlgray font-bold text-xl"><span class="text-4xl mx-3">6</span> Ihr ImuPro</h1>
                    <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                        <img class="w-4/6 py-6 mx-auto" src="../assets/6.png" alt="idea">
                    </div>
                    <p class="mt-6 text-justify">In wenigen Tagen erhalten Sie Ihren persönlichen Befund. Sie starten mit Ihrer Ernährungsumstellung und werden bei Fragen professionell von uns unterstützt.</p>
                </div>
            </div>
        </div>

        <div class="grid" :class="{'grid-cols-2': slide!==1 && slide !==6, 'grid-cols-1': slide==1 || slide ==6}">
            <div id="button" v-if="slide!==1" class="bg-imupro rounded-lg p-2 mx-6 my-4 relative right-0 text-center cursor-pointer block md:hidden" @click="slide = slide == 1 ? slide : slide-1">
                Zurück
            </div>
            <div id="button" v-if="slide!==6" class="bg-imupro rounded-lg p-2 mx-6 my-4 relative right-0 text-center cursor-pointer block md:hidden" @click="slide = slide > 5 ? slide : slide+1">
                Weiter
            </div>
        </div>

        
    </section>
</template>
<style>

</style>
<script>
export default {
  name: 'CTLOnboarding',
  data () {
    return {
      slide: 1
    }
  },
}
</script>