<template>
    <footer class="w-full h-20 bg-imupro rounded-t-2xl shadow-2xl p-6 mt-6">
      <div class="float-left">© CTL & Ortholabor<br><span class="text-xs text-ctlgray float-left" style="line-height: 0.7rem;">Icons entnommen von <br><a href="https://www.flaticon.com/">https://www.flaticon.com/</a></span></div>
      <div class="float-right"><a href="https://www.ctl-labor.de/impressum/">Impressum</a></div>
    </footer>
</template>
<style>

</style>
<script>
export default {
  name: 'CTLFooter'
}
</script>