<template>
    <section class="z-0 mt-10">
      <div class="h-1 w-10/12 rounded-xl mx-auto bg-imupro -rotate-3 mb-16"></div>

      <div id="headline">
        <h1 class="text-center text-ctlgray font-bold text-xl mt-6 mx-6">Weitergehende Informationen</h1>
        <p class="mt-4 mx-6 md:mx-48 xl:mx-72 text-justify">Wünschen Sie zusätzliche Informationen zu ihrem persönlichen Befund? <br> Unsere ImuPro Ernährungsberatung hilft Ihnen gerne weiter. Nehmen Sie hierzu jederzeit Kontakt mit uns auf:</p>
      </div>

      <div class="grid-cols-1 md:grid-cols-2 mt-6 z-0 grid">
          <div id="introduction">
              <div class="my-6 mx-6 md:mx-16 xl:mx-28">
                <a href="tel:044036260522">
                  <h1 class="text-center text-ctlgray font-bold text-xl">Telefonische Ernährungsberatung</h1>
                  <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                      <img class="w-4/6 py-6 mx-auto" src="../assets/phone.png" alt="idea">
                  </div>
                  <p class="mt-6 text-center text-lg text-ctlgray">04403 62605-22</p>
                </a>
              </div>
          </div>
          <div id="gettherapist">
              <div class="my-6 mx-6 md:mx-16 xl:mx-28">
                <a href="mailto:beratung@ctl-labor.de">
                  <h1 class="text-center text-ctlgray font-bold text-xl">Anfrage per E-Mail</h1>
                  <div class="w-1/3 mx-auto rounded-full bg-imupro relative mt-6 shadow-md">
                      <img class="w-4/6 py-6 mx-auto" src="../assets/mail.png" alt="idea">
                  </div>
                  <p class="mt-6 text-center text-lg text-ctlgray">beratung@ctl-labor.de</p>
                </a> 
              </div>
          </div>
      </div>
    </section>
</template>
<style>

</style>
<script>
export default {
  name: 'AdditionalInformation'
}
</script>