<template>
  <CTLHeader />
  <CTLOnboarding />
  <AdditionalInformation />
  <AdditionalLinks />
  <CTLFooter />
</template>

<script>
import CTLHeader from './components/Header.vue'
import CTLOnboarding from './components/Onboarding.vue'
import AdditionalInformation from './components/AdditionalInformation.vue'
import AdditionalLinks from './components/AdditionalLinks.vue'
import CTLFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    CTLHeader,
    CTLOnboarding,
    AdditionalInformation,
    AdditionalLinks,
    CTLFooter
  }
}
</script>

<style>
#app {
  font-family: Arial, Helvetica, Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
