<template>
    <header class="sticky top-0 w-full h-32 bg-imupro rounded-b-2xl shadow-2xl mb-6 z-50">
        <img class="mx-auto md:mx-6 h-12 md:h-20 mb-4 md:my-6 md:float-left relative top-2 md:top-0" alt="CTL Logo" src="../assets/CTL_Logo.png" />
        <h1 class="text-center text-ctlgray font-bold text-xl mt-0 md:mt-20 md:float-left absolute w-full">Diagnostik für mehr Lebensqualität - Herzlich willkommen</h1>
    </header>
</template>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
export default {
  name: 'CTLHeader'
}
</script>